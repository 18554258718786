.auth__wrapper { display: flex; justify-content: center; flex-direction: column; position: fixed; top: 0; bottom: 0; left: 0; right: 0; }
.auth__scrollable { overflow-y: auto; }
.auth { width: 280px; margin: 50px auto; }
.auth__form { position: relative; }
.auth__title { font: 600 21px/28px var(--font); text-align: center; margin: 0 0 28px 0; }
.auth__input { width: 100%; height: 48px; border: 1px solid #d5d5d5; color: #494949; font: 400 18px/18px var(--font); padding: 0 15px; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.auth__input--email { border-radius: 6px 6px 0 0; }
.auth__input--password { border-radius: 0 0 6px 6px; margin: -1px 0 0 0; padding-right: 50px; }
.auth__input:focus { border-color: #83bffc; position: relative; z-index: 1; }
.auth__submit { position: absolute; width: 26px; height: 26px; border: 1px solid #000; background: url(i/right-arrow.svg) no-repeat 5px 5px / 15px; bottom: 11px; right: 10px; border-radius: 50%; opacity: .5; transition: opacity 150ms; z-index: 1; cursor: pointer; }
.auth__guest { margin: 28px 0 0 0; font: 400 15px/16px var(--font); text-align: center; opacity: .5; text-decoration: underline; cursor: pointer; transition: opacity 150ms; }

.auth__buttons { text-align: center; margin: 28px 0 0 0; }
.auth__button-wrapper { display: flex; gap: 18px; justify-content: space-between; align-items: center; }
.auth__button-wrapper--center { justify-content: center; }
.auth__button-wrapper + .auth__button-wrapper { margin: 18px 0 0 0; }
.auth__button { font: 400 12px/14px var(--font); color: #ccc; border-bottom: 1px dashed #ccc; cursor: pointer; }

/* HOVER */
@media screen and (min-width: 769px) {
    .auth__input:hover { border-color: #83bffc; position: relative; z-index: 1; }
    .auth__submit:hover { opacity: 1; }
    .auth__guest:hover { opacity: 1; }
    .auth__button:hover { color: #ca302a; border-bottom-color: #ca302a; }
}