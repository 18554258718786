.form { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,.18); z-index: 1000; display: flex; flex-direction: column; align-items: center; justify-content: center; }
.form__scrollable { overflow-y: auto; width: 100%; }
.form__content { background: #fff; width: 328px; margin: 50px auto; border-radius: 5px; }
.form__header { position: relative; height: 50px; box-shadow: 0 1px 5px -1px rgba(0,0,0,.18); padding: 0 38px 0 18px; }
.form__close { width: 28px; height: 28px; background: url(../i/close.svg) no-repeat 50% / 12px; position: absolute; top: 0; bottom: 0; right: 10px; margin: auto; cursor: pointer; opacity: .4; transition: opacity 150ms; }
.form__title { font: 400 21px/50px var(--font); text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
.form__field { padding: 18px; position: relative; }
.form__field--disable { pointer-events: none; opacity: .2; cursor: default; }
.form__field + .form__field { margin-top: -18px; }
.form__field--flex { display: flex; }
.form__inline { flex: 1; }
.form__inline + .form__inline { margin-left: 18px; }
.form__clear { width: 28px; height: 28px; background: url(../i/close.svg) no-repeat 50% / 12px; cursor: pointer; opacity: .4; transition: opacity 150ms; position: absolute; margin: 0 0 0 15px; }
.form__caption { font: 400 12px/12px var(--font); padding: 0 5px; background: #fff; margin: -5px 0 5px 8px; color: #aaa; z-index: 1; position: relative; }
.form__caption--required:after { content: ''; width: 5px; height: 5px; position: absolute; background: #ca302a; border-radius: 50%; margin: -1px 0 0 3px; }
.form__image-wrapper { width: 80px; height: 80px; display: block; border-radius: 5px; cursor: pointer; overflow: hidden; margin: 0 0 38px 0; }
.form__image-wrapper--wide { width: 200px; height: 116px; }
.form__file { position: absolute; left: -8888px; }
.form__image { width: 100%; height: 100%; background: #f0f0f0 no-repeat 50% / contain; }
.form__image-remove { position: absolute; font: 400 12px/12px var(--font); color: #ca302a; margin: -28px 0 0 0; width: 80px; text-align: center; cursor: pointer; }
.form__image-wrapper--wide + .form__image-remove { width: 200px; }
.form__hint { font: 400 12px/14px var(--font); color: #aaa; }
.form__reset { font: 400 12px/14px var(--font); color: #ca302a; cursor: pointer; display: inline-block; vertical-align: baseline; }

/* HOVER */
@media screen and (min-width: 769px) {
    .form__close:hover { opacity: .8; }
    .form__clear:hover { opacity: .8; }
    .form__image-remove:hover { text-decoration: underline; }
    .form__reset:hover { text-decoration: underline; }
}

@media screen and (max-width: 375px) {
    .form__content { width: 312px; }
}