.course__content { margin: 0 0 18px 0; }
.course__name-wrapper { position: relative; padding: 0 228px 0 0; }
.course__buy { position: absolute; right: 0; padding: 0 28px; font: 400 16px/36px var(--font); background: #07a23b; color: #fff; border-radius: 18px; cursor: pointer; transition: background-color 150ms; }
.course__name { font: 800 28px/48px var(--font); margin: 0 0 18px 0; }
.course__description { font: 400 18px/24px var(--font); }
.course__videos { display: flex; gap: 18px; }
.course__video { width: 220px; }
.course__video-image { width: 100%; height: 120px; border-radius: 8px; cursor: pointer; overflow: hidden; position: relative; }
.course__video--block > .course__video-image { cursor: default; }
.course__video-mask { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,.12) no-repeat 50% / 28px; }
.course__video--block > .course__video-image > .course__video-mask { background-image: url(i/lock.svg); pointer-events: none; cursor: default; }
.course__video-img { object-fit: cover; width: 100%; height: 100%; }
.course__video-duration { position: absolute; bottom: 3px; right: 3px; background: #fff; padding: 0 8px; border-radius: 5px; font: 400 12px/14px var(--font); }
.course__video-meta { margin: 5px 0 0 0; padding: 0 5px; }
.course__video-name { font: 400 12px/16px var(--font); color: #aaa; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

/* HOVER */
@media screen and (min-width: 769px) {
    .course__buy:hover { background-color: #077f36; }
}