.course__item { box-shadow: 2px 2px 10px rgba(0,0,0,.14); border-radius: 8px; overflow: hidden; width: 300px; cursor: pointer; transition: box-shadow 150ms, transform 150ms; }
.course__item-preview-wrapper { height: 200px; }
.course__item-preview { width: 100%; height: 100%; object-fit: cover; }
.course__item-content { padding: 12px; }
.course__item-name { font: 600 16px/21px var(--font); color: var(--font-color); margin: 0 0 8px 0; }
.course__item-meta { display: flex; gap: 18px; align-items: center; justify-content: space-between; margin: 0 0 8px 0; }
.course__item-meta-unit { padding: 0 0 0 22px; position: relative; font: 400 12px/18px var(--font); color: #aaa; }
.course__item-meta-unit:before { content: ''; width: 18px; height: 18px; position: absolute; left: 0; top: 0; bottom: 0; margin: auto; background: no-repeat 50% / 16px; opacity: .5; }
.course__item-meta-unit--level:before { background-image: url(i/bar-chart.svg); }
.course__item-meta-item--duration:before { background-image: url(i/calendar.svg); }
.course__item-description { font: 400 12px/18px var(--font); color: var(--font-color); -webkit-line-clamp: 3; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; }

/* HOVER */
@media screen and (min-width: 1024px) {
    .course__item:hover { box-shadow: 5px 5px 15px rgba(0,0,0,.28); transform: translateY(-1px); }
}