.content { padding: 28px; }

.input__wrapper { position: relative; margin: 0 0 28px 0; }
.input { border: 1px solid #ddd; border-radius: 5px; padding: 0 12px; font: 400 16px/28px var(--font); height: 30px; width: 100%; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.input--inactive { color: #808080; pointer-events: none; }
.input--relative { position: relative; }
.input:not(.input--inactive):not(.input--cell):focus { border-color: var(--main-color); box-shadow: 0 0 0 1px var(--main-color); }
.input--filter:focus { border-color: #09f !important; box-shadow: 0 0 0 1px #09f !important; }
input[type=date].input--empty, input[type=time].input--empty { color: #ccc; }
.input--textarea { resize: none; min-height: 69px; line-height: 24px; padding-top: 8px; padding-bottom: 8px; }
.input__caption { font: 400 12px/12px var(--font); padding: 0 5px; background: #fff; margin: -5px 0 5px 8px; color: #aaa; z-index: 1; position: relative; }
.input__caption--required:after { content: ''; width: 5px; height: 5px; position: absolute; background: #ca302a; border-radius: 50%; margin: -1px 0 0 3px; }
.input--tick { padding-right: 28px; position: relative; cursor: pointer; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.input--tick:after { content: ''; width: 15px; height: 15px; background: url(/src/assets/i/right-arrow.svg) no-repeat 50% / 5px; position: absolute; right: 5px; top: 0; bottom: 0; margin: auto; opacity: .4; transform: rotate(-270deg); transition: opacity 150ms; }

.button { background: var(--main-color); color: #fff; font: 600 16px/32px var(--font); height: 32px; border-radius: 5px; text-align: center; cursor: pointer; transition: background-color 150ms; padding: 0 18px; }

/* HOVER */
@media screen and (min-width: 769px) {
    .input:not(.input--inactive):not(.input--cell):hover { border-color: var(--main-color); box-shadow: 0 0 0 1px var(--main-color); }
    .input--filter:hover { border-color: #09f !important; box-shadow: 0 0 0 1px #09f !important; }
    .input--tick:hover:after { opacity: .8; }
    .button:hover { background-color: #276e77; }
}