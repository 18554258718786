.buy { position: fixed; top: 0; left: 0; right: 0; bottom: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; }
.buy__scrollable { overflow-y: auto; width: 100%; }
.buy__content { background: #fff; width: 320px; margin: 50px auto; border-radius: 5px; }
.buy__status { display: flex; align-items: center; justify-content: center; gap: 12px; margin: 0 0 12px 0; }
.buy__ico { width: 58px; height: 58px; background: no-repeat 50% / 38px; flex-shrink: 0; }
.buy--success .buy__ico { background-image: url(i/check-circle.svg); }
.buy--error .buy__ico { background-image: url(i/x-circle.svg); }
.buy__text { font: 600 21px/28px var(--font); }
.buy__redirect-wrapper { text-align: center; }
.buy__redirect { font: 400 16px/28px var(--font); cursor: pointer; }

/* HOVER */
@media screen and (min-width: 769px) {
    .buy__redirect:hover { text-decoration: underline; }
}