.growl { position: fixed; z-index: 1000; right: 30px; top: 30px; width: 300px;  }
.growl__item { position: relative; background: #fff; box-shadow: 5px 5px 15px 0 rgba(0,0,0, .28); border: 2px solid transparent; margin: 0; transition: margin 200ms ease, max-height 200ms, padding 250ms, transform 350ms 200ms cubic-bezier(.175, .885, .32, 1.15), opacity 200ms cubic-bezier(.2, 0, .2, 1); max-height: 0; min-height: 0; -webkit-transform: translateX(120%); transform: translateX(120%); border-radius: 10px; }
.growl__item:before { content: ""; display: inline-block; vertical-align: middle; min-height: 38px; }
.growl__item--shown { -webkit-transform: translateX(0); transform: translateX(0); margin-bottom: 10px; max-height: 200px; padding: 12px 15px 12px 58px; min-height: 62px; }
.growl__item--collapsed { -webkit-transform: translateX(120%); transform: translateX(120%); }
.growl__item--warning { border-color: #fdbd00; }
.growl__item--error { border-color: #fe858a; }
.growl__item--success { border-color: #07a23b; }
.growl__ico { position: absolute; width: 40px; height: 40px; top:0; bottom: 0; margin: auto; left: 12px; background: no-repeat 50%; opacity: 0; transition: opacity 250ms 300ms; }
.growl__item--shown > .growl__ico { opacity: 1; }
.growl__item--warning > .growl__ico { background-image: url(i/warning.svg); background-size: 25px; }
.growl__item--error > .growl__ico { background-image: url(i/error.svg); background-size: 25px; }
.growl__item--success > .growl__ico { background-image: url(i/success.svg); background-size: 25px; }
.growl__text { font: 400 15px/18px var(--font); display: inline-block; vertical-align: middle; opacity: 0; transition: opacity 250ms 300ms; }
.growl__item--shown > .growl__text { opacity: 1; }