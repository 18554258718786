.header { height: 60px; box-shadow: 0 2px 10px rgba(0,0,0,.14); display: flex; justify-content: space-between; align-items: center; padding: 0 28px; }
.header__navs { display: flex; gap: 12px; }
.header__nav { font: 400 16px/21px var(--font); color: var(--font-color); cursor: pointer; }
.header__nav--active { color: var(--main-color); }
.header__signout { font: 400 16px/21px var(--font); color: var(--font-color); cursor: pointer; }
.header__signin { font: 400 16px/21px var(--font); color: var(--font-color); cursor: pointer; }

/* HOVER */
@media screen and (min-width: 1024px) {
    .header__nav:hover { text-decoration: underline; }
    .header__signout:hover { text-decoration: underline; }
}