.playable { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,.18); z-index: 1000; display: flex; flex-direction: column; align-items: center; justify-content: center; }
.playable__scrollable { overflow-y: auto; width: 100%; }
.playable__content { margin: 50px auto; }
.playable__close { width: 28px; height: 28px; background: #fff url(/src/assets/i/close.svg) no-repeat 50% / 12px; position: absolute; top: 50px; right: 50px; cursor: pointer; opacity: .4; transition: opacity 150ms; border-radius: 50%; }
.playable__video { text-align: center; }
.playable__video > video { max-height: calc(100vh - 100px); border-radius: 28px; }

/* HOVER */
@media screen and (min-width: 769px) {
    .playable__close:hover { opacity: 1; }
}