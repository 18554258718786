@font-face {
    font-family: 'Onest';
    src: url('Onest-Regular.ttf');
    src: local('Onest-Regular'),
    url('Onest-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('Onest-SemiBold.ttf');
    src: local('Onest-SemiBold'),
    url('Onest-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('Onest-Bold.ttf');
    src: local('Onest-Bold'),
    url('Onest-Bold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}